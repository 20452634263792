<template>
  <div class="view">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'worksheetTypeDefinition', params: {} }"
      >
        Worksheet Type
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :to="{ name: 'worksheetTypeDefinitionView', params: {} }"
      >
        View Worksheet Type
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="loaded">
      <v-card-title> View Worksheet Type </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-if="worksheetTypeDefinition.worksheetType">
                <td max-width="35%" class="text-subtitle-1">Type</td>
                <td>{{ worksheetTypeDefinition.worksheetType }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasDocumentNumber">
                <td max-width="35%" class="text-subtitle-1">Has Document Number</td>
                <td>{{ worksheetTypeDefinition.hasDocumentNumber | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasWeightSettings">
                <td max-width="35%" class="text-subtitle-1">
                  Has Weight Setings:
                </td>
                <td>{{ worksheetTypeDefinition.hasWeightSettings | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasLocation">
                <td max-width="35%" class="text-subtitle-1">Has Location</td>
                <td>{{ worksheetTypeDefinition.hasLocation | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasProcessType">
                <td max-width="35%" class="text-subtitle-1">
                  Has Process Type:
                </td>
                <td>{{ worksheetTypeDefinition.hasProcessType | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasProduct">
                <td max-width="35%" class="text-subtitle-1">Has Product</td>
                <td>{{ worksheetTypeDefinition.hasProduct | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasDeductibleWeight">
                <td max-width="35%" class="text-subtitle-1">
                  Has Deductible Weight:
                </td>
                <td>{{ worksheetTypeDefinition.hasDeductibleWeight | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasTargetWeight">
                <td max-width="35%" class="text-subtitle-1">
                  Has Target Weight:
                </td>
                <td>{{ worksheetTypeDefinition.hasTargetWeight | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasNetAdjustmentWeight">
                <td max-width="35%" class="text-subtitle-1">
                  Has Net Adjustment Weight:
                </td>
                <td>{{ worksheetTypeDefinition.hasNetAdjustmentWeight | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasMemo">
                <td max-width="35%" class="text-subtitle-1">Has Memo</td>
                <td>{{ worksheetTypeDefinition.hasMemo | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasEntryPhoto">
                <td max-width="35%" class="text-subtitle-1">
                  Has Require Photo:
                </td>
                <td>{{ worksheetTypeDefinition.hasEntryPhoto | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasNotes">
                <td max-width="35%" class="text-subtitle-1">
                  Has Notes:
                </td>
                <td>{{ worksheetTypeDefinition.hasNotes | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasEntryNotes">
                <td max-width="35%" class="text-subtitle-1">
                  Has Entry Notes:
                </td>
                <td>{{ worksheetTypeDefinition.hasEntryNotes | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasSupplier">
                <td max-width="35%" class="text-subtitle-1">Has Supplier</td>
                <td>{{ worksheetTypeDefinition.hasSupplier | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasCustomer">
                <td max-width="35%" class="text-subtitle-1">Has Customer</td>
                <td>{{ worksheetTypeDefinition.hasCustomer | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.hasInventorySelection">
                <td max-width="35%" class="text-subtitle-1">Has Inventory Selection</td>
                <td>{{ worksheetTypeDefinition.hasInventorySelection | yesNo }}</td>
              </tr>

              <tr v-if="worksheetTypeDefinition.createDate">
                <td max-width="35%" class="text-subtitle-1">Created On</td>
                <td>{{ worksheetTypeDefinition.createDate | formatDateTime }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between ma-5">
      <v-btn @click="goBack">Back</v-btn>
      <v-btn @click="edit(id)" color="primary">Edit</v-btn>
      <span></span>
    </div>
  </div>
</template>

<script>
import { getWorksheetTypeDefinition } from "@/services/worksheetTypeDefinition";

export default {
  data() {
    return {
      id: "",
      worksheetTypeDefinition: {},
      loaded: false,
      relations: {},
    };
  },
  filters:{
    yesNo(val){
      return val ? 'Yes':'No';
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    edit(id) {
      this.$router.push({
        name: "worksheetTypeDefinitionEdit",
        params: { id: id },
      });
    },
    setInstance() {
      this.setDependencies().then(() => {
        getWorksheetTypeDefinition(this.id)
          .then((response) => {
            this.worksheetTypeDefinition = response.data;
            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading worksheet type");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      return null;
    },
    getRelationText(id, relationProperty, relationAttribute) {
      const relationList = this.relations[relationProperty];
      if (relationList) {
        const relationObj = relationList.find((r) => r.id == id);
        return relationObj[relationAttribute];
      } else {
        return "";
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.setInstance();
    this.$emit("showParent", false);
  },
};
</script>
